import api from "@modules/api/api";
import Location from "@modules/locations/types/Location";
import { QueryClient, useQuery } from "@tanstack/react-query";

const getLocations = (): Promise<Location[]> => {
  return api.get<Location[]>("api/locations");
};

export const prefetchLocations = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: ["locationList"],
    queryFn: () => getLocations(),
  });
};

const defaultSort = (locations: Location[]): Location[] => {
  return locations.sort((a, b) => a.name.localeCompare(b.name));
};

export default function useGetLocations() {
  const { data, error } = useQuery({
    queryKey: ["locationList"],
    queryFn: () => getLocations(),
  });

  return {
    locations: data ? defaultSort(data) : [],
    isLoading: !error && !data,
    error,
  };
}
