const retries = 2;
const intervalMs = 1000;
const backoffFactor = 2;

const sendRequest = (
  params: google.maps.GeocoderRequest,
  errorMessage: string
): Promise<google.maps.GeocoderResult[] | null> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    const doGeocode = (retryCount: number) => {
      geocoder.geocode(params, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else if (retryCount > 0) {
          setTimeout(
            () => doGeocode(retryCount - 1),
            intervalMs * backoffFactor ** (retries - retryCount)
          );
        } else {
          reject(new Error(errorMessage));
        }
      });
    };
    doGeocode(retries);
  });
};

export const geocodeRequest = async (
  address: string
): Promise<google.maps.GeocoderResult[] | null> => {
  const params: google.maps.GeocoderRequest = {
    address,
    componentRestrictions: { country: "ca" },
  };
  return sendRequest(params, "Geocoding request failed");
};

export const reverseGeocodeRequest = async (
  latLng: google.maps.LatLngLiteral
): Promise<google.maps.GeocoderResult[] | null> => {
  const params: google.maps.GeocoderRequest = {
    location: latLng,
  };
  return sendRequest(params, "Reverse Geocoding request failed");
};
