import OverlayView from "./OverlayView";
import CustomMarker from "./CustomMarker";

type Props = {
  position: google.maps.LatLng;
  map?: google.maps.Map;
  index?: number;
};

const MapMarkerWrapper = ({ position, map, index }: Props) => {
  return (
    <>
      {/* button as the marker */}
      {map && (
        <OverlayView position={position} map={map}>
          <CustomMarker
            index={index}
            numberingStyle="top-[15%] left-[40%]"
            mapPinCircleStyle="w-[2.7rem]"
            rrmapIconStyle="w-[2.16rem]"
          />
        </OverlayView>
      )}
    </>
  );
};

export default MapMarkerWrapper;
