import MapPinCircle from "@public/images/map-pin-circle.png";
import LocalImage from "@common/components/LocalImage";
import { RRMapIcon } from "@common/components/icons";

type Props = {
  index: number | undefined;
  numberingStyle?: string;
  mapPinCircleStyle?: string;
  rrmapIconStyle?: string;
};

const CustomMarker = ({
  index,
  numberingStyle,
  mapPinCircleStyle,
  rrmapIconStyle,
}: Props) => {
  return (
    <button type="button" disabled>
      {index ? (
        <>
          <h5 className={`absolute text-black text-lg ${numberingStyle}`}>
            {index}
          </h5>
          <LocalImage
            src={MapPinCircle}
            alt="Map Pin Circle"
            className={mapPinCircleStyle}
          />
        </>
      ) : (
        <RRMapIcon className={rrmapIconStyle} />
      )}
    </button>
  );
};

export default CustomMarker;
