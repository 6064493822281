import { ImageLoader } from "next/image";

const cloudflareImagesLoader: ImageLoader = ({ src, width }) =>
  src
    .replace(
      "https://imagedelivery.net",
      `${
        process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGES_DOMAIN ?? ""
      }/cdn-cgi/imagedelivery`
    )
    .replace(/\/w=\d+$/, `/w=${width}`);

export default cloudflareImagesLoader;
