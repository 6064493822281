import { Instant } from "@js-joda/core";
import { PaymentFrequency } from "./PaymentFrequency";
import { VehicleWarranty } from "./VehicleWarranty";

type VehicleList = {
  id: string;
  stockNumber: string;
  year: number;
  make: string;
  model: string;
  trim: string;
  exteriorColour: string;
  bodyStyle: string;
  images: Array<{ img: string; position: number }>;
  createdAt: Instant;
  odometerCount: number;
  vin?: string;
  dealerName?: string;
  pricing: {
    listingPrice: number;
    previousListingPrice: number | null;
    paymentAmount: number;
    paymentFrequency: PaymentFrequency;
    term: number;
    interestRate: number;
    interestPaid: number;
    cashDown: number;
    tradeInValue: number;
  };
  warranty: VehicleWarranty;
};

export function vehicleListReviver(
  key: string,
  value: number | string | object
) {
  if (value === null || value === undefined) return value;
  if (typeof value === "string") {
    switch (key) {
      case "createdAt":
        return Instant.parse(value);
      default:
        return value;
    }
  }
  return value;
}

export default VehicleList;
