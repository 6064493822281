import { useState, useImperativeHandle, forwardRef } from "react";
import Location from "@modules/locations/types/Location";
import { Status } from "@googlemaps/react-wrapper";
import { geocodeRequest } from "@common/helpers/googleMaps/geocoderService";
import Map from "./Map";
import MapMarkerWrapper from "./MapMarkerWrapper";

export const render = (status: Status) => {
  return <span>{`${status.toLowerCase()}...`}</span>;
};

export const DEFAULT_CENTER = { lat: 54.7565, lng: -95.423 }; // Center of Canada
export const SEARCH_ZOOM = { min: 3, max: 11 };
const CANADA_BOUNDS: google.maps.LatLngBoundsLiteral = {
  north: 61.5,
  south: 41.67,
  west: -145.0,
  east: -50.97,
};

const MAP_STYLE = [
  {
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        color: "#d5f8ff",
      },
    ],
  },
];

const MapHandler = forwardRef((props, ref) => {
  const [zoom, setZoom] = useState(SEARCH_ZOOM.min);
  const defaultCenter = new google.maps.LatLng(
    DEFAULT_CENTER.lat,
    DEFAULT_CENTER.lng
  );
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: defaultCenter.lat(),
    lng: defaultCenter.lng(),
  });
  const [markersLatLng, setMarkersLatLng] = useState<google.maps.LatLng[]>([]);
  const [showMarkerIndex, setShowMarkerIndex] = useState<boolean>(false);
  const [markerBounds, setMarkerBounds] = useState<google.maps.LatLngBounds>();

  useImperativeHandle(ref, () => ({
    centerMapAddress(address: string): google.maps.LatLng | null {
      geocodeRequest(address)
        .then((results) => {
          if (results == null || results.length === 0) return null;

          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat: lat(), lng: lng() });
          setZoom(8);

          return results[0].geometry.location;
        })
        .catch(() => {
          return null;
        });

      return null;
    },
    centerMapLatLng(location: google.maps.LatLng, zoomNum = 8): void {
      setCenter({ lat: location.lat(), lng: location.lng() });
      setZoom(zoomNum);
    },
    setInitialZoom(initZoom: number) {
      setZoom(initZoom);
    },
    setMapMarkers(locations: Location[], showIndex = false): void {
      if (locations && locations.length > 0) {
        const latLngList: google.maps.LatLng[] = locations.flatMap(
          (location) => {
            return new google.maps.LatLng({
              lat: location.address.latitude || 0,
              lng: location.address.longitude || 0,
            });
          }
        );
        setMarkersLatLng(latLngList);
        setShowMarkerIndex(showIndex);

        if (showIndex) {
          const bounds = new google.maps.LatLngBounds();
          latLngList.forEach((marker) => {
            bounds.extend({ lat: marker.lat(), lng: marker.lng() });
          });
          setMarkerBounds(bounds);
        }
      }
    },
  }));

  const onClick = (e: google.maps.MapMouseEvent) => {
    e.stop();
  };

  const getMarkersOverlay = () => {
    if (markersLatLng !== undefined && markersLatLng.length !== 0) {
      return markersLatLng.map((position, index) => {
        const markerIndex = showMarkerIndex ? index + 1 : undefined;
        return (
          <MapMarkerWrapper
            position={position}
            index={markerIndex}
            key={position.toString()}
          />
        );
      });
    }
    return null;
  };

  return (
    <Map
      center={center}
      onClick={onClick}
      zoom={zoom}
      searchZoom={SEARCH_ZOOM}
      style={{ flexGrow: "1", height: "100%" }}
      styles={MAP_STYLE}
      restriction={{
        latLngBounds: CANADA_BOUNDS,
        strictBounds: false,
      }}
      disableDefaultUI
      zoomControl={false}
      markerBounds={markerBounds}
    >
      {getMarkersOverlay()}
    </Map>
  );
});

export default MapHandler;
