import { UserSavedSearches } from "../types/UserSavedSearches";
import { VehicleFiltersData } from "../types/vehicleFilter";
import { VehicleSearchRecord } from "../types/VehicleSearchRecord";
import convertSearchFiltersToFilterstate from "./convertSearchFiltersToFilterState";

export default function processSavedSearchVehicleRecord(
  vehicleFiltersData: VehicleFiltersData,
  savedSearchesList?: UserSavedSearches[]
): VehicleSearchRecord[] {
  const searches: VehicleSearchRecord[] = [];

  if (!vehicleFiltersData) {
    return searches;
  }

  savedSearchesList?.map((savedSearch) => {
    const filterData: VehicleSearchRecord = {
      name: "",
      id: "",
      hasNotification: false,
    };

    filterData.id = savedSearch.id;

    filterData.name = savedSearch.name;
    filterData.hasNotification = savedSearch.hasNotification;
    filterData.filters = convertSearchFiltersToFilterstate(
      savedSearch.filters,
      vehicleFiltersData
    );

    return searches.push(filterData);
  });

  return searches;
}
