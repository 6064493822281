import api from "@modules/api/api";
import { useQuery } from "@tanstack/react-query";
import { VehicleSearchSuggestionsResponse } from "../types/VehicleSearchSuggestionsResponse";

const getSuggestions = (text: string) => {
  return api.get<VehicleSearchSuggestionsResponse>(
    `api/vehicles/search/suggestions?text=${encodeURIComponent(text.trim())}`
  );
};

export default function useSearchSuggestions(text: string) {
  const { data, isError, error } = useQuery({
    queryKey: ["searchSuggestion", text],
    queryFn: () => getSuggestions(text),
    enabled: !!text?.trim(),
  });

  return {
    suggestions: data?.suggestions.map((s) => s) || [],
    isLoading: !error && !data,
    isError,
  };
}
