function createOverlay(
  containerArg: HTMLElement,
  paneArg: keyof google.maps.MapPanes,
  positionArg: google.maps.LatLng | google.maps.LatLngLiteral
) {
  class Overlay extends google.maps.OverlayView {
    container: HTMLElement;

    pane: keyof google.maps.MapPanes;

    position: google.maps.LatLng | google.maps.LatLngLiteral;

    constructor(
      container: HTMLElement,
      pane: keyof google.maps.MapPanes,
      position: google.maps.LatLng | google.maps.LatLngLiteral
    ) {
      super();
      this.container = container;
      this.pane = pane;
      this.position = position;
    }

    onAdd(): void {
      const pane = this.getPanes()?.[this.pane];
      pane?.appendChild(this.container);
    }

    draw(): void {
      const projection = this.getProjection();
      const point = projection.fromLatLngToDivPixel(this.position);
      if (point === null) {
        return;
      }

      // Since the marker div is rendered and referenced to the top-left tip of the latlng point
      // we need to adjust div position slightly in order for the "tear drop" to appear above pointing down at the location.
      const offsets = { x: -22, y: -50 };
      this.container.style.transform = `translate(${point.x + offsets.x}px, ${
        point.y + offsets.y
      }px)`;
    }

    onRemove(): void {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }
  return new Overlay(containerArg, paneArg, positionArg);
}

export default createOverlay;
