import Image, { ImageProps } from "next/image";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";

type Props = Omit<ImageProps, "loader">;

const CloudflareImagesImage = ({ src, ...props }: Props) => {
  // eslint-disable-next-line react/forbid-elements
  return <Image src={src} loader={cloudflareImagesLoader} {...props} />;
};

export default CloudflareImagesImage;
