import { Dispatch, createContext, SetStateAction } from "react";
import { FilterAction, VehicleFilters } from "./types/vehicleFilter";

export interface VehicleFilterContextState {
  vehicleFilters: VehicleFilters;
  dispatch: Dispatch<FilterAction>;
  showVehicleFilterState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const VehicleFilterContext = createContext<VehicleFilterContextState>(
  {} as VehicleFilterContextState
);

export default VehicleFilterContext;
