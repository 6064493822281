export default function getRelativeLink(urlString: string) {
  try {
    if (urlString.startsWith("/")) {
      if (urlString.includes("#")) {
        return `#${urlString.split("#").pop()}`;
      }
      return urlString;
    }
    if (urlString.toLowerCase().includes("rightride.com")) {
      const url = new URL(urlString);
      if (url.hash) {
        return url.hash;
      }
      if (url.search) {
        return `${url.pathname}${url.search}`;
      }
      return url.pathname;
    }
    return urlString;
  } catch {
    return "/";
  }
}
