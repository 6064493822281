import Location from "@modules/locations/types/Location";
import MapLocation from "@modules/locations/types/MapLocation";

const DEFAULT_MAX_DISTANCE_IN_METRES = 4e5;
const WIDTH_OF_CANADA_IN_METRES = 5.514e6;

interface DistanceMatrixRequest {
  origin: google.maps.LatLngLiteral;
  destinations: google.maps.LatLngLiteral[];
}

/**
 * Calculates great-circle distances between the two points – that is,
 * the shortest distance over the earth’s surface – using the ‘Haversine’ formula.
 *
 * https://stackoverflow.com/a/27943
 */
function getDistanceFromLatLon(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
) {
  const deg2rad = (deg: number): number => deg * (Math.PI / 180);

  const R = 6.371e6; // Radius of the earth in meters
  const dLat = deg2rad(destination.lat - origin.lat);
  const dLng = deg2rad(destination.lng - origin.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(origin.lat)) *
      Math.cos(deg2rad(destination.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in meters

  return d;
}

export const isLocationWithinDefaultMaxRange = (
  row: google.maps.DistanceMatrixResponseElement
) => {
  return (
    row.status === "OK" && row.distance.value <= DEFAULT_MAX_DISTANCE_IN_METRES
  );
};

export const getDistance = (row: google.maps.DistanceMatrixResponseElement) => {
  return row.status === "OK" ? row.distance.value : WIDTH_OF_CANADA_IN_METRES;
};

export const findClosestLocationIndex = (
  rows: google.maps.DistanceMatrixResponseElement[]
): number => {
  let nearestIndex = 0;

  for (let i = 1; i < rows.length; i += 1) {
    if (
      rows[i].status === "OK" &&
      rows[i].distance.value < rows[nearestIndex].distance.value
    ) {
      nearestIndex = i;
    }
  }

  return nearestIndex;
};

export const isValidAutoCompleteAddress = (
  place: google.maps.places.PlaceResult
) => {
  return place.address_components && place.address_components.length > 2;
};

export const isAutoCompleteProvince = (
  place: google.maps.places.PlaceResult
) => {
  return place.address_components && place.address_components.length === 2;
};

const getDistanceMatrix = (
  request: DistanceMatrixRequest
): google.maps.DistanceMatrixResponseElement[] => {
  return request.destinations.map((destination) => {
    const distance = getDistanceFromLatLon(request.origin, destination);
    const distanceInKm = distance / 1000;

    return {
      distance: {
        text: `${distanceInKm.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })} km`,
        value: distance,
      },
      status: "OK",
    } as google.maps.DistanceMatrixResponseElement;
  });
};

export const getDistanceEvaluationGeneric = (
  origin: google.maps.LatLngLiteral,
  locations: {
    latitude: number;
    longitude: number;
  }[]
): google.maps.DistanceMatrixResponseElement[] => {
  if (!origin || locations.length === 0) return [];

  const result = getDistanceMatrix({
    origin,
    destinations: locations.map((l) => ({ lat: l.latitude, lng: l.longitude })),
  });

  return result;
};

const getDistanceEvaluation = (
  originLatitude: number,
  originLongitude: number,
  locations: MapLocation[]
): google.maps.DistanceMatrixResponseElement[] => {
  return getDistanceEvaluationGeneric(
    { lat: originLatitude, lng: originLongitude },
    locations
      .map((l) => l.address)
      .map((a) => ({ latitude: a.latitude || 0, longitude: a.longitude || 0 }))
  );
};

export function findClosestLocation(
  latitude: number,
  longitude: number,
  locations: MapLocation[]
): MapLocation | null {
  const distances = getDistanceEvaluation(latitude, longitude, locations);

  if (!distances || distances.length === 0) {
    return null;
  }

  return locations[findClosestLocationIndex(distances)];
}

export function convertDealerLocationToMapLocation(
  dealerLocation: Location
): MapLocation {
  return {
    id: dealerLocation.id,
    name: dealerLocation.name,
    distance: 0,
    isShown: false,
    address: {
      address: dealerLocation.address.address,
      address2: dealerLocation.address.address2,
      city: dealerLocation.address.city,
      province: dealerLocation.address.province,
      postalCode: dealerLocation.address.postalCode,
      latitude: dealerLocation.address.latitude,
      longitude: dealerLocation.address.longitude,
    },
  };
}

export default getDistanceEvaluation;
