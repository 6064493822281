import Modal from "@common/components/Modal";
import { CheckCircleIcon } from "@common/components/icons";
import { useTranslation } from "next-i18next";

type Props = {
  isOpen: boolean;
  setState: (state: boolean) => void;
  modalDescription?: string;
};

const FormSubmissionModal = ({
  isOpen,
  setState,
  modalDescription = undefined,
}: Props) => {
  const { t } = useTranslation(["common"]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setState(false);
      }}
      alignment="center"
      customIcon={
        <div className="relative bg-gray-25 inline-flex items-center justify-center rounded-full h-12 w-12 p-2">
          <span className="absolute text-center bg-white w-5 h-5 z-0" />
          <CheckCircleIcon className="text-green-400 w-10 h-10 z-10" />
        </div>
      }
      title={t("common:form_success_modal_title")}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="text-center mb-4 w-7/12">
          <h5 className="text-center text-primary-deep pb-2">
            {t("common:form_success_modal_header")}
          </h5>
          <div className="text-left md:text-center">
            <p className="text-primary-deep">
              {modalDescription || t("common:form_success_modal_description")}
            </p>
          </div>
        </div>
      </div>
      <Modal.Buttons
        primaryButtonText={t("common:form_success_modal_button")}
        onPrimaryClick={() => {
          setState(false);
        }}
      />
    </Modal>
  );
};

export default FormSubmissionModal;
