import { CheckboxFieldLayout } from "../types/GravityFormLayout";

// Remove any single quotes from the label
export const sanitizeFieldName = (label: string) => label.replace(/'/g, "");

// If name field label contains "friend" then map to DB column "friendsFirstName" or "friendsLastName"
export const getNameFieldName = (label: string, customLabel?: string) =>
  (customLabel || label).trim().toLowerCase().includes("friend")
    ? `friends${label}Name`
    : `${label.toLocaleLowerCase()}Name`;

// If email field label contains "friend" then map to DB column "referralEmail"
export const getEmailFieldName = (label: string, customLabel?: string) =>
  (customLabel || label).trim().toLowerCase().includes("friend")
    ? "referralEmail"
    : "email";

// If name field label contains "friend" then map to DB column "referralPhoneNumber"
export const getPhoneFieldName = (label: string) =>
  label.trim().toLowerCase().includes("friend")
    ? "referralPhoneNumber"
    : "phoneNumber";

// This logic is to handle the different dropdowns that we have DB columns for.
export const getDropdownName = (label: string) => {
  switch (true) {
    // Check #1 - If the label contains the word "location" or "locations" or "preferred location" then "preferredLocation".
    case /location|locations|preferred\s*location/i.test(label):
      return "preferredLocation";
    // Check #2 - If the label contains the word "what are you inquiring about" then "purpose".
    case /what\s*are\s*you\s*inquiring\s*about/i.test(label):
      return "purpose";
    // Check #3 - If the label contains the word "choose your offer" or "chosen offer" then "chosenOffer".
    case /choose\s*your\s*offer|chosen\s*offer/i.test(label):
      return "chosenOffer";
    // If none of the above, then use the label as is, and include it in the "message".
    default:
      return sanitizeFieldName(label);
  }
};

// Get list of checkbox names in the format of "checkboxLabel-choice"
export const getCheckboxNames = (checkboxField: CheckboxFieldLayout) =>
  checkboxField.choices.map(
    (input) => `${sanitizeFieldName(checkboxField.label)}-${input.text}`
  );
