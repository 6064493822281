import { CSSProperties } from "react";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import { DEFAULT_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import { CmsBackgroundType } from "../types/CmsBackground";

const GetBackgroundStyle = (
  background: CmsBackgroundType
): CSSProperties | undefined => {
  if (background.option === "Color") {
    if (
      background.secondaryBackground.active &&
      background.secondaryBackground.secondaryBackgroundColor
    ) {
      return {
        background: `linear-gradient(${
          background.secondaryBackground.backgroundDirection === "On Bottom"
            ? "to top"
            : "to bottom"
        }, ${background.secondaryBackground.secondaryBackgroundColor} ${
          background.secondaryBackground.backgroundPercentage ?? "40"
        }%, ${background.backgroundColor ?? "transparent"} ${
          background.secondaryBackground.backgroundPercentage ?? "40"
        }%)`,
      };
    }
    return {
      backgroundColor: background.backgroundColor ?? "transparent",
    };
  }
  if (background.option === "Image" && background.backgroundImage) {
    return {
      backgroundImage: `url(${cloudflareImagesLoader({
        src: background.backgroundImage[0].sourceUrl ?? "",
        width: DEFAULT_BACKGROUND_WIDTH,
      })})`,
    };
  }
  return undefined;
};

export default GetBackgroundStyle;
