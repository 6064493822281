import api from "@modules/api/api";
import { getGuestUser, User } from "@modules/user/types/user";
import userQueryKeys from "@modules/user/userQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { VehicleFilter } from "../types/vehicleFilter";
import { convertSavedFilters } from "./useSaveSearchMutation";
import { UserSavedSearches } from "../types/UserSavedSearches";

type RecentSearchRequest = {
  name: string;
  vehicleFilters: VehicleFilter[];
};

const recentSearch = async (
  recentSearchRequest: RecentSearchRequest
): Promise<UserSavedSearches[]> => {
  return api.post<UserSavedSearches[]>(
    "api/customers/me/vehicle-searches/recent",
    {
      json: {
        name: recentSearchRequest.name,
        vehicleFilters: convertSavedFilters(recentSearchRequest.vehicleFilters),
      },
    }
  );
};

export default function useSaveRecentSearchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: recentSearch,
    onSuccess: (recentSearches) => {
      queryClient.setQueryData(
        userQueryKeys.user(),
        (data: User | undefined) => {
          const user = data || getGuestUser();

          return {
            ...user,
            recentSearches,
          };
        }
      );
    },
  });
}
