import MapWrapperProps from "@modules/location/helpers/mapWrapperProps";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { ReactNode, useState } from "react";

type Props = {
  children: ReactNode | ((status: Status) => ReactNode);
  loadingCallback?: (status: Status) => void;
};

const GoogleMapsWrapper = ({ children, loadingCallback }: Props) => {
  const [mapStatus, setMapStatus] = useState<Status>(Status.LOADING);

  try {
    return (
      <>
        {typeof children === "function" && children(mapStatus)}
        <Wrapper
          apiKey={MapWrapperProps.apiKey}
          libraries={MapWrapperProps.libraries}
          callback={(status) => {
            setMapStatus(status);
            loadingCallback?.(status);
          }}
        >
          {typeof children !== "function" && children}
        </Wrapper>
      </>
    );
  } catch (error) {
    return typeof children === "function" ? (
      <>{children(Status.FAILURE)}</>
    ) : (
      <div>{children}</div>
    );
  }
};

export default GoogleMapsWrapper;
