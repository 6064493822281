import React, {
  useEffect,
  useState,
  useRef,
  isValidElement,
  Children,
  cloneElement,
} from "react";

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  markerBounds?: google.maps.LatLngBounds;
  searchZoom: { min: number; max: number };
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  markerBounds,
  searchZoom,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useEffect(() => {
    if (map) {
      map.setOptions(options);

      // Have to explictily set center as map.setOptions seems to not set center on first invocation.
      if (options.center) {
        map.setCenter(options.center);
      }
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    if (map && markerBounds) {
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        const currentMapZoom = map.getZoom() || 0;
        if (currentMapZoom > searchZoom.max) {
          map.setZoom(searchZoom.max);
        }
      });
      map.fitBounds(markerBounds);
    }
  }, [map, markerBounds, searchZoom.max]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        return isValidElement(child) ? cloneElement(child, { map }) : child;
      })}
    </>
  );
};

export default Map;
