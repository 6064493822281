import {
  CheckboxFieldSubmissionLayout,
  ConsentFieldSubmissionLayout,
  DropdownFieldSubmissionLayout,
  EmailFieldSubmissionLayout,
  NameFieldSubmissionLayout,
  PhoneFieldSubmissionLayout,
  SingleLineInputFieldSubmissionLayout,
  TextAreaFieldSubmissionLayout,
} from "../types/GravityFormLayout";

const conditionalLogicProperty = `
    conditionalLogic {
      actionType
      logicType
      rules {
        fieldId
        operator
        value
      }
    }`;

const nameFieldProperty = `
    ... on NameField {
          isRequired
          errorMessage
          cssClass
          inputs {
            label
            ... on NameInputProperty {
              label
              isHidden
              customLabel
              placeholder
              autocompleteAttribute
            }
          }
          ${conditionalLogicProperty}
        }
  `;

const emailFieldProperty = `
    ... on EmailField {
          isRequired
          errorMessage
          cssClass
          inputs {
            ... on EmailInputProperty {
              label
              customLabel
              placeholder
              autocompleteAttribute
            }
          }
          ${conditionalLogicProperty}
        }
  `;

const phoneFieldProperty = `
    ... on PhoneField {
          isRequired
          errorMessage
          cssClass
          label
          placeholder
          autocompleteAttribute
          ${conditionalLogicProperty}
        }
  `;

const dropdownFieldProperty = `
    ... on SelectField {
          label
          isRequired
          errorMessage
          cssClass
          placeholder
          choices {
            text
            value
          }
          ${conditionalLogicProperty}
        }
  `;

const checkboxFieldProperty = `
    ... on CheckboxField {
          label
          isRequired
          errorMessage
          cssClass
          choices {
            text
            value
          }
          ${conditionalLogicProperty}
        }
  `;

const textAreaFieldProperty = `
    ... on TextAreaField {
          label
          isRequired
          errorMessage
          cssClass
          maxLength
          placeholder
          ${conditionalLogicProperty}
        }
  `;

const consentFieldProperty = `
    ... on ConsentField {
          isRequired
          errorMessage
          cssClass
          checkboxLabel
          ${conditionalLogicProperty}
        }
  `;

const singleLineInputFieldProperty = `
    ... on TextField {
          label
          isRequired
          errorMessage
          cssClass
          maxLength
          placeholder
          ${conditionalLogicProperty}
        }
  `;

const sectionBreakFieldProperty = `
    ... on SectionField {
          label
          cssClass
        }
  `;

export default function getFormLayoutQueries() {
  return `
    ${nameFieldProperty}
    ${emailFieldProperty}
    ${phoneFieldProperty}
    ${dropdownFieldProperty}
    ${checkboxFieldProperty}
    ${consentFieldProperty}
    ${textAreaFieldProperty}
    ${singleLineInputFieldProperty}
    ${sectionBreakFieldProperty}
    `;
}

const sanitizeValue = (value: string) => value.replace(/"/g, '\\"');

export function getFormSubmissionLayoutQueries({
  formId,
  formData,
}: {
  formId: number;
  formData: [
    | NameFieldSubmissionLayout
    | EmailFieldSubmissionLayout
    | PhoneFieldSubmissionLayout
    | DropdownFieldSubmissionLayout
    | CheckboxFieldSubmissionLayout
    | ConsentFieldSubmissionLayout
    | SingleLineInputFieldSubmissionLayout
    | TextAreaFieldSubmissionLayout
  ];
}): { query: string; operationName: string } {
  const fieldValues = formData.map((field) => {
    const serializedField = Object.entries(field).map(([key, value]) => {
      if (typeof value === "string") {
        return `${key}: "${sanitizeValue(value)}"`;
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return `${key}: []`;
        }
        if (typeof value[0] === "object") {
          return `${key}: [${value
            .map(
              (values) =>
                `{${Object.entries(values)
                  .map(
                    ([k, v]) =>
                      `${k}: ${
                        typeof v === "string" ? `"${sanitizeValue(v)}"` : v
                      }`
                  )
                  .join(", ")}}`
            )
            .join(", ")}]`;
        }
        return `${key}: [${value
          .map((v) => `"${sanitizeValue(v)}"`)
          .join(", ")}]`;
      }
      if (typeof value === "object") {
        const serializedObject = `{${Object.entries(value)
          .map(
            ([k, v]) =>
              `${k}:${typeof v === "string" ? `"${sanitizeValue(v)}"` : v}`
          )
          .join(", ")}}`;
        return `${key}: ${serializedObject}`;
      }
      return `${key}: ${value}`;
    });
    return `{${serializedField}}`;
  });

  return {
    query: `
      mutation SubmitGfForm {
        submitGfForm(
          input: {
            id: ${formId},
            fieldValues: [
              ${fieldValues.join(", ")}
            ],
            saveAsDraft: false
          }
        ) {
          errors {
            id
            message
          }
        }
      }
    `,
    operationName: "SubmitGfForm",
  };
}
