import { cleanParagraphTags } from "@common/helpers/cleanHtml";

type Props = {
  title: string;
  description: string;
};

const FormContentHeader = ({ title, description }: Props) => {
  return (
    <>
      {/* eslint-disable react/no-danger */}
      <h5
        className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
        dangerouslySetInnerHTML={{ __html: cleanParagraphTags(title) }}
      />
      <p
        className="body-2"
        dangerouslySetInnerHTML={{ __html: cleanParagraphTags(description) }}
      />
      {/* eslint-enable */}
    </>
  );
};

export default FormContentHeader;
