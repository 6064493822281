import { WrapperProps } from "@googlemaps/react-wrapper";
import { render } from "@modules/location/components/MapHandler";

type Props = {
  apiKey: string;
  libraries: (
    | "drawing"
    | "geometry"
    | "localContext"
    | "places"
    | "visualization"
  )[];
} & Pick<WrapperProps, "render">;

const MapWrapperProps: Props = {
  apiKey: process.env.NEXT_PUBLIC_GOOG_MAPS_KEY || "",
  libraries: ["places", "drawing"],
  render,
};

export default MapWrapperProps;
