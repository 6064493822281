import { captureMessage, SeverityLevel } from "@sentry/nextjs";

const logMessage = (message: string, severityLevel: SeverityLevel) => {
  captureMessage(message, severityLevel);
};

export function logError(message: string) {
  logMessage(message, "error");
}

export function logWarning(message: string) {
  logMessage(message, "warning");
}

const logger = {
  logError,
  logWarning,
};

export default logger;
