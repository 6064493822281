import api from "@modules/api/api";
import { useMutation } from "@tanstack/react-query";
import { logError } from "@common/helpers/logger";
import HTTPErrorWithBody from "@modules/api/HTTPErrorWithBody";
import { Lead } from "../types/Lead";

const postLeadCreate = async (lead: Lead): Promise<string> => {
  return api.post<string>("api/leads", {
    json: lead,
  });
};

export default function useCreateLeadMutation() {
  return useMutation({
    mutationFn: postLeadCreate,
    retry: 2,
    retryDelay: 3000,
    onError: (error) => {
      if (error instanceof HTTPErrorWithBody) {
        logError(
          `Unable to submit lead after 3 attempts: ${JSON.stringify(
            error.responseJson
          )}`
        );
        return;
      }
      logError(`Unable to submit lead after 3 attempts: ${error}`);
    },
  });
}
