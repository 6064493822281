import { Translation } from "@common/types/Translation";
import { Popover } from "@headlessui/react";
import { Instant } from "@js-joda/core";
import processSavedSearchVehicleRecord from "@modules/vehicle/helpers/processSavedSearchVehicleRecord";
import { getFuelEfficiencyFilterTranslations } from "@modules/vehicle/helpers/getVehicleFilterDescription";
import useSearchSuggestions from "@modules/vehicle/hooks/useSearchSuggestions";
import { UserSavedSearches } from "@modules/vehicle/types/UserSavedSearches";
import {
  FilterKeys,
  VehicleFilter,
  VehicleFiltersData,
} from "@modules/vehicle/types/vehicleFilter";
import { VehicleSearchRecord } from "@modules/vehicle/types/VehicleSearchRecord";
import { useTranslation } from "next-i18next";
import { gtmPushData } from "@common/helpers/gtm";
import { PaymentFrequencies } from "@modules/vehicle/types/PaymentFrequency";
import useDebounce from "@common/hooks/useDebounce";

const shuffle = <T,>(array: T[]) => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

const randomIndexes = shuffle([...Array(11)].map((a, i) => i)).slice(0, 5);

const searchLabel = (searchName: string) => {
  const yearRangeRegex = /(?:^|\s)(19\d\d|2\d{3}) +– (2\d{3})$/;
  const yearRangeMatch = searchName.match(yearRangeRegex);

  if (!yearRangeMatch) {
    return searchName;
  }

  return (
    <div>
      {searchName.split(" ")[0]}
      <span className="pl-3 body-4 text-gray-500">{yearRangeMatch[0]}</span>
    </div>
  );
};

const recentSearches = (
  vehicleFiltersData: VehicleFiltersData | null,
  recentSearchesList?: UserSavedSearches[],
  savedSearchesCount?: number
): VehicleSearchRecord[] => {
  if (!recentSearchesList || !vehicleFiltersData) {
    return [];
  }

  let searches = processSavedSearchVehicleRecord(
    vehicleFiltersData,
    recentSearchesList
  );

  if (savedSearchesCount && savedSearchesCount + searches.length > 5) {
    searches = searches.slice(0, 0 - savedSearchesCount);
  }

  return searches;
};

const savedSearches = (
  vehicleFiltersData: VehicleFiltersData | null,
  savedSearchesList?: UserSavedSearches[]
): VehicleSearchRecord[] => {
  if (!savedSearchesList || !vehicleFiltersData) {
    return [];
  }

  const sortedSavedSearch = savedSearchesList?.sort((a, b) => {
    return Instant.parse(b.updatedAt.toString()).compareTo(
      Instant.parse(a.updatedAt.toString())
    );
  });

  const searches = processSavedSearchVehicleRecord(
    vehicleFiltersData,
    sortedSavedSearch
  );

  return searches;
};

const popularSearches = (
  vehicleFiltersData: VehicleFiltersData | null
): VehicleSearchRecord[] => {
  if (!vehicleFiltersData) return [];

  const currentYear = new Date().getFullYear();
  const threeYearsPast = currentYear - 3;
  const luxuryMakes = [
    "Acura",
    "Audi",
    "BMW",
    "Genesis",
    "Infiniti",
    "Jaguar",
    "Lexus",
    "Lincoln",
    "Mercedes-Benz",
  ];
  const suvBodyStyle = vehicleFiltersData.bodyStyles.find((b) => b.id === 4);
  const sedanBodyStyle = vehicleFiltersData.bodyStyles.find((b) => b.id === 1);
  const coupeBodyStyle = vehicleFiltersData.bodyStyles.find((b) => b.id === 2);
  const hatchBackBodyStyle = vehicleFiltersData.bodyStyles.find(
    (b) => b.id === 3
  );
  const truckBodyStyle = vehicleFiltersData.bodyStyles.find((b) => b.id === 6);
  const hybridFuelType = vehicleFiltersData.fuelTypes.find((f) => f.id === 4);
  const dieselFuelType = vehicleFiltersData.fuelTypes.find((f) => f.id === 2);

  const cityFuelEfficiencyConsumptionType =
    getFuelEfficiencyFilterTranslations()[0];
  const appleCarplayOrAndroidAutoFeature = vehicleFiltersData.featureTypes
    .find((ft) => ft.id === 2)
    ?.displayFeatures.find((df) => df.id === 9);

  const heatedSeatsAutoFeature = vehicleFiltersData.featureTypes
    .find((ft) => ft.id === 1)
    ?.displayFeatures.find((df) => df.id === 2);

  const luxuryMakesFilter = (): VehicleFilter[] =>
    vehicleFiltersData.makesAndModels
      .filter((m) => luxuryMakes.find((j) => j === m.name))
      .map((m) => ({
        key: FilterKeys.Make,
        state: {
          make: m.name,
        },
      }));

  const jeepFilter = (): VehicleFilter[] =>
    vehicleFiltersData.makesAndModels
      .filter((m) => m.name === "Jeep")
      .map((m) => ({
        key: FilterKeys.Make,
        state: {
          make: m.name,
        },
      }));

  const searches: VehicleSearchRecord[] = [
    {
      id: "1",
      name: `SUVs ${threeYearsPast} – ${currentYear}`,
      filters: [
        {
          key: FilterKeys.BodyStyle,
          state: {
            id: suvBodyStyle?.id.toString() || "",
            name: suvBodyStyle?.name || ({} as Translation),
          },
        },
        {
          key: FilterKeys.Year,
          state: {
            minYear: {
              id: threeYearsPast.toString(),
              value: threeYearsPast,
            },
            maxYear: {
              id: currentYear.toString(),
              value: currentYear,
            },
          },
        },
      ],
    },
    {
      id: "4",
      name: "Efficient Cars",
      filters: [
        {
          key: FilterKeys.BodyStyle,
          state: {
            id: sedanBodyStyle?.id.toString() || "",
            name: sedanBodyStyle?.name || ({} as Translation),
          },
        },
        {
          key: FilterKeys.BodyStyle,
          state: {
            id: coupeBodyStyle?.id.toString() || "",
            name: coupeBodyStyle?.name || ({} as Translation),
          },
        },
        {
          key: FilterKeys.BodyStyle,
          state: {
            id: hatchBackBodyStyle?.id.toString() || "",
            name: hatchBackBodyStyle?.name || ({} as Translation),
          },
        },
        {
          key: FilterKeys.FuelEfficiency,
          state: {
            consumptionType: cityFuelEfficiencyConsumptionType,
            maxLP100K: 10,
            minLP100K: 0,
          },
        },
      ],
    },
    {
      id: "5",
      name: "Trucks",
      filters: [
        {
          key: FilterKeys.BodyStyle,
          state: {
            id: truckBodyStyle?.id.toString() || "",
            name: truckBodyStyle?.name || ({} as Translation),
          },
        },
      ],
    },
    {
      id: "6",
      name: "Apple CarPlay / Android Auto",
      filters: [
        {
          key: FilterKeys.KeyFeatures,
          state: {
            id: appleCarplayOrAndroidAutoFeature?.id.toString() || "",
            name: appleCarplayOrAndroidAutoFeature?.name || ({} as Translation),
          },
        },
      ],
    },
    { id: "10", name: "Luxury Rides", filters: luxuryMakesFilter() },
    {
      id: "8",
      name: "Under 50,000 KM",
      filters: [
        {
          key: FilterKeys.Mileage,
          state: {
            minMileage: 0,
            maxMileage: 50000,
          },
        },
      ],
    },
    {
      id: "2",
      name: "Hybrids",
      filters: [
        {
          key: FilterKeys.FuelType,
          state: {
            id: hybridFuelType?.id.toString() || "",
            name: hybridFuelType?.name || ({} as Translation),
          },
        },
      ],
    },
    {
      id: "3",
      name: "Vehicles Under $30,000",
      filters: [
        {
          key: FilterKeys.Price,
          state: {
            minPrice: 0,
            maxPrice: 30000,
          },
        },
      ],
    },
    {
      id: "7",
      name: "Under $450 Per Month",
      filters: [
        {
          key: FilterKeys.Payment,
          state: {
            minPayment: 0,
            maxPayment: 450,
            paymentFrequency: PaymentFrequencies.monthly,
            cashDown: 2000,
            tradeInValue: 0,
          },
        },
      ],
    },
    {
      id: "9",
      name: "Diesel Engines",
      filters: [
        {
          key: FilterKeys.FuelType,
          state: {
            id: dieselFuelType?.id.toString() || "",
            name: dieselFuelType?.name || ({} as Translation),
          },
        },
      ],
    },
    {
      id: "11",
      name: "Jeeps with Heated Seats",
      filters: [
        ...jeepFilter(),
        {
          key: FilterKeys.KeyFeatures,
          state: {
            id: heatedSeatsAutoFeature?.id.toString() || "",
            name: heatedSeatsAutoFeature?.name || ({} as Translation),
          },
        },
      ],
    },
  ];

  return randomIndexes.map((i) => searches[i]);
};

type Props = {
  searchText: string;
  onSearchSelect: (
    selectedSearch: VehicleSearchRecord,
    saveSearch?: boolean
  ) => void;
  filterData: VehicleFiltersData;
  savedSearchesData?: UserSavedSearches[];
  recentSearchesData?: UserSavedSearches[];
};

const SuggestedSearchBox = ({
  searchText,
  onSearchSelect,
  filterData,
  savedSearchesData,
  recentSearchesData,
}: Props) => {
  const { t } = useTranslation(["common"]);

  const debouncedSearchText = useDebounce(searchText, 350);
  const { suggestions, isError, isLoading } =
    useSearchSuggestions(debouncedSearchText);

  return (
    <>
      {(!savedSearchesData || savedSearchesData.length === 0) &&
        (!recentSearchesData || recentSearchesData.length === 0) && (
          <div className="uppercase body-3 text-gray-500 py-4 px-2 font-semibold">
            {!searchText && t("common:popular_searches")}
            {searchText && t("common:search_suggestions")}
          </div>
        )}

      {savedSearchesData && savedSearchesData.length > 0 && searchText && (
        <div className="uppercase body-3 text-gray-500 py-4 px-2 font-semibold">
          {t("common:search_suggestions")}
        </div>
      )}

      {!searchText &&
        (!savedSearchesData || savedSearchesData.length === 0) &&
        (!recentSearchesData || recentSearchesData.length === 0) &&
        popularSearches(filterData).map((search) => (
          <div key={search.id}>
            <Popover.Button
              key={search.id}
              className="relative cursor-pointer p-2 rounded-lg w-full text-left text-gray-900 hover:bg-primary-bright/10 focus:outline-none focus:bg-primary-bright/20"
              onClick={() => onSearchSelect(search, false)}
            >
              <span className="block truncate body-3 text-gray-600">
                {searchLabel(search.name)}
              </span>
            </Popover.Button>
          </div>
        ))}

      {!searchText && savedSearchesData && savedSearchesData.length > 0 && (
        <div className="uppercase body-3 text-gray-500 py-4 px-2 font-semibold">
          {t("common:your_saved_searches")}
        </div>
      )}
      {!searchText &&
        savedSearches(filterData, savedSearchesData).map((savedSearch) => (
          <div key={savedSearch.id}>
            <Popover.Button
              key={savedSearch.id}
              className="relative cursor-pointer p-2 rounded-lg w-full text-left text-gray-900 hover:bg-primary-bright/10 focus:outline-none focus:bg-primary-bright/20"
              onClick={() => onSearchSelect(savedSearch, false)}
            >
              <span className="block truncate body-3 text-gray-600">
                {savedSearch.name}
              </span>
            </Popover.Button>
          </div>
        ))}

      {!searchText &&
        recentSearchesData &&
        recentSearchesData.length !== 0 &&
        savedSearchesData &&
        savedSearchesData.length < 5 && (
          <>
            <div className="uppercase body-3 text-gray-500 py-4 px-2 font-semibold">
              {t("common:recent_searches")}
            </div>
            {recentSearches(
              filterData,
              recentSearchesData,
              savedSearchesData ? savedSearchesData.length : 0
            ).map((recentSearch) => {
              return (
                <div key={recentSearch.name}>
                  <Popover.Button
                    className="flex flex-row gap-2 relative cursor-pointer p-2 rounded-lg w-full text-left text-gray-900 hover:bg-primary-bright/10 focus:outline-none focus:bg-primary-bright/20"
                    onClick={() => {
                      gtmPushData({
                        event: "Navigation",
                        element: "Search",
                        subElement: recentSearch.name,
                      });
                      onSearchSelect(recentSearch);
                    }}
                  >
                    <span className="block truncate body-3 text-gray-600">
                      {recentSearch.name}
                    </span>
                  </Popover.Button>
                </div>
              );
            })}
          </>
        )}

      {searchText && isLoading && <div>{t("common:loading")}</div>}
      {searchText && !isLoading && isError && (
        <div>{t("common:error_occurred")}</div>
      )}
      {searchText &&
        !isLoading &&
        !isError &&
        suggestions.length > 0 &&
        suggestions.slice(0, 5).map((suggestion) => (
          <div key={suggestion}>
            <Popover.Button
              className="relative cursor-pointer p-2 rounded-lg w-full text-left text-gray-900 hover:bg-primary-bright/10 focus:outline-none focus:bg-primary-bright/20"
              onClick={() =>
                onSearchSelect(
                  {
                    name: suggestion,
                    id: suggestion,
                    searchText: suggestion,
                  },
                  false
                )
              }
            >
              <span className="block truncate body-3 text-gray-600">
                {suggestion}
              </span>
            </Popover.Button>
          </div>
        ))}
      {searchText && !isLoading && !isError && suggestions.length === 0 && (
        <div>{t("common:no_search_suggestions")}</div>
      )}
    </>
  );
};

export default SuggestedSearchBox;
