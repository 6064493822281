import { useMutation } from "@tanstack/react-query";
import nextApi from "@modules/api/nextApi";
import {
  GravityFormLayout,
  NameFieldLayout,
  NameFieldSubmissionLayout,
  EmailFieldLayout,
  EmailFieldSubmissionLayout,
  PhoneFieldLayout,
  PhoneFieldSubmissionLayout,
  DropdownFieldLayout,
  DropdownFieldSubmissionLayout,
  CheckboxFieldLayout,
  CheckboxFieldSubmissionLayout,
  TextAreaFieldLayout,
  TextAreaFieldSubmissionLayout,
  GravityFormInput,
  ConsentFieldSubmissionLayout,
  ConsentFieldLayout,
  SingleLineInputFieldLayout,
  SingleLineInputFieldSubmissionLayout,
} from "../types/GravityFormLayout";
import { getFormSubmissionLayoutQueries } from "../api/formLayoutQueries";
import {
  getCheckboxNames,
  getDropdownName,
  getEmailFieldName,
  getNameFieldName,
  getPhoneFieldName,
  sanitizeFieldName,
} from "../helpers/gravityFormsFieldName";

type Props = {
  formId: number;
  formFields: GravityFormLayout["form"]["formFields"]["nodes"];
  formInput: GravityFormInput;
};

const mapFieldsToSubmissionLayouts = (
  formFields: GravityFormLayout["form"]["formFields"]["nodes"],
  formInput: GravityFormInput
) =>
  formFields
    .map((field) => {
      switch (field.type) {
        case "NAME": {
          const nameField = field as NameFieldLayout;
          let firstNameField = "";
          let lastNameField = "";
          nameField.inputs.forEach((input) => {
            const nameInput = getNameFieldName(input.label, input.customLabel);
            if (input.label === "First") {
              firstNameField = nameInput;
            }
            if (input.label === "Last") {
              lastNameField = nameInput;
            }
          });
          return {
            id: nameField.databaseId,
            nameValues: {
              first: formInput[firstNameField],
              last: formInput[lastNameField],
            },
          } as NameFieldSubmissionLayout;
        }
        case "EMAIL": {
          const emailField = field as EmailFieldLayout;
          const emailInput = getEmailFieldName(
            emailField.inputs[0].label,
            emailField.inputs[0].customLabel
          );
          return {
            id: emailField.databaseId,
            emailValues: {
              value: formInput[emailInput],
            },
          } as EmailFieldSubmissionLayout;
        }
        case "PHONE": {
          const phoneField = field as PhoneFieldLayout;
          const phoneInput = getPhoneFieldName(phoneField.label);
          return {
            id: phoneField.databaseId,
            value: formInput[phoneInput],
          } as PhoneFieldSubmissionLayout;
        }
        case "SELECT": {
          const dropdownField = field as DropdownFieldLayout;
          const selectedValue = formInput[getDropdownName(dropdownField.label)];
          if (selectedValue) {
            return {
              id: dropdownField.databaseId,
              value: (selectedValue as { id: string; name: string }).name,
            } as DropdownFieldSubmissionLayout;
          }
          break;
        }
        case "CHECKBOX": {
          const checkboxField = field as CheckboxFieldLayout;
          const checkboxNames = getCheckboxNames(checkboxField);
          const checkboxValues = checkboxNames
            .map((checkbox, index) => {
              if (formInput[checkbox]) {
                return {
                  inputId: checkboxField.databaseId + (index + 1) / 10,
                  value: checkbox.split("-")[1],
                };
              }
              return null;
            })
            .filter((checkbox) => checkbox);
          if (checkboxValues.length !== 0) {
            return {
              id: checkboxField.databaseId,
              checkboxValues,
            } as CheckboxFieldSubmissionLayout;
          }
          break;
        }
        case "TEXTAREA": {
          const textAreaField = field as TextAreaFieldLayout;
          const textAreaLabel = sanitizeFieldName(textAreaField.label);
          return {
            id: textAreaField.databaseId,
            value: formInput[textAreaLabel],
          } as TextAreaFieldSubmissionLayout;
        }
        case "CONSENT": {
          const consentField = field as ConsentFieldLayout;
          if (formInput.consent) {
            return {
              id: consentField.databaseId,
              value: "true",
            } as ConsentFieldSubmissionLayout;
          }
          break;
        }
        case "TEXT": {
          const singleLineInputFieldLayout =
            field as SingleLineInputFieldLayout;
          const singleLineInputLabel = sanitizeFieldName(
            singleLineInputFieldLayout.label
          );
          return {
            id: singleLineInputFieldLayout.databaseId,
            value: formInput[singleLineInputLabel],
          } as SingleLineInputFieldSubmissionLayout;
        }
        default:
          return null;
      }
      return null;
    })
    .filter((field) => field) as [
    | NameFieldSubmissionLayout
    | EmailFieldSubmissionLayout
    | PhoneFieldSubmissionLayout
    | DropdownFieldSubmissionLayout
    | CheckboxFieldSubmissionLayout
    | TextAreaFieldSubmissionLayout
    | ConsentFieldSubmissionLayout
    | SingleLineInputFieldSubmissionLayout
  ];

const postEntryCreate = async ({
  formId,
  formFields,
  formInput,
}: Props): Promise<string> => {
  const query = getFormSubmissionLayoutQueries({
    formId,
    formData: mapFieldsToSubmissionLayouts(formFields, formInput),
  });
  return nextApi.post<string>("api/cms/postGravityFormEntry", {
    body: JSON.stringify(query),
  });
};

export default function useCreateGravityFormEntryMutation() {
  return useMutation({ mutationFn: postEntryCreate });
}
