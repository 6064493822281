import classNames from "classnames";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";

type Props = {
  backgroundImageGroup?: BackgroundImageGroup;
};

const FormSelectorBackgroundImage = ({ backgroundImageGroup }: Props) => {
  if (
    !backgroundImageGroup ||
    (backgroundImageGroup && !backgroundImageGroup?.image)
  ) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          "hidden xl:flex flex-1 lg:basis-[40%] w-full relative overflow-auto bg-no-repeat bg-center ",
          {
            "lg:bg-left": backgroundImageGroup?.imagePosition === "Right",
            "lg:bg-right": backgroundImageGroup?.imagePosition === "Left",
          }
        )}
      />

      <div
        className={classNames(
          "flex xl:hidden flex-1 lg:basis-[40%] xl:basis-[50%] w-full relative overflow-auto bg-no-repeat bg-center bg-contain lg:bg-cover",
          {
            "lg:bg-left px-5 lg:px-0 xl:px-5":
              backgroundImageGroup?.imagePosition === "Right",
            "lg:bg-right px-5 lg:px-0 xl:px-5":
              backgroundImageGroup?.imagePosition === "Left",
          }
        )}
        style={
          backgroundImageGroup?.image &&
          backgroundImageGroup?.image.length > 0 &&
          backgroundImageGroup?.image[0].sourceUrl
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src:
                    backgroundImageGroup?.image.length > 0
                      ? backgroundImageGroup?.image[0].sourceUrl || ""
                      : "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div className="min-w-full h-auto relative inset-0">
          <div className="overflow-auto relative h-[15rem] md:h-[32rem]" />
        </div>
      </div>
    </>
  );
};

export default FormSelectorBackgroundImage;
