import api from "@modules/api/api";
import { User, getGuestUser } from "@modules/user/types/user";
import { useQuery } from "@tanstack/react-query";
import { getProvinceByAbbr } from "@common/types/Province";
import userQueryKeys from "../userQueryKeys";

const reviverFunction = (
  key: string,
  value: string | number | object | null | undefined
) => {
  if (key === "province" || key === "locationProvince") {
    return getProvinceByAbbr(value as string) || null;
  }
  return value;
};

const getUser = async (): Promise<User> => {
  const cacheBreaker = +new Date();
  try {
    return await api.get<User>(`api/accounts/user?_=${cacheBreaker}`, {
      parseJson: (text: string) => JSON.parse(text, reviverFunction),
    });
  } catch {
    return getGuestUser();
  }
};

export default function useUser() {
  const {
    data: user,
    error,
    refetch,
    isFetched,
    isFetching,
  } = useQuery({
    queryKey: userQueryKeys.user(),
    queryFn: getUser,
    refetchOnMount: true,
    refetchOnWindowFocus: "always",
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity,
    // maybe?
    placeholderData: getGuestUser(),
  });

  return {
    user,
    error,
    isLoading: !error && !user,
    refetch,
    isFetched,
    isFetching,
  };
}
