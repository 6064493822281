import { gtmPushData } from "@common/helpers/gtm";
import { truncateFullWords } from "@common/helpers/stringHelper";
import translate from "@common/helpers/translate";
import api from "@modules/api/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  BodyStyleFilter,
  ExteriorColourFilter,
  FeatureFilter,
  FilterKeys,
  MakeFilter,
  MileageFilter,
  ModelFilter,
  TransmissionFilter,
  YearFilter,
  FuelEfficiencyFilter,
  SortByFilter,
  FuelTypeFilter,
  PaymentFilter,
  PriceFilter,
  LocationFilter,
  VehicleFiltersData,
  ProvinceFilter,
  PassengerCapacityFilter,
} from "../types/vehicleFilter";
import VehicleList, { vehicleListReviver } from "../types/vehicleList";
import { VehicleListQuery } from "../types/VehicleListQuery";
import { SearchFilters } from "../types/SearchFilters";
import vehicleQueryKeys from "../vehicleQueryKeys";
import { PaymentFrequency } from "../types/PaymentFrequency";
import { LowestPaymentSortBy } from "../types/VehicleSortOptions";

export const maxSearchLength = 128;

export type PaymentFactors = {
  paymentFrequency: PaymentFrequency;
  cashDown: number;
  tradeInValue: number;
};
export interface VehicleListQueryResult {
  vehicles: VehicleList[];
  matchedFilters: SearchFilters;
  nextPageIndex?: number;
  totalItems?: number;
  isRecommended?: boolean;
  hasPersonalInterestRate: boolean;
  hasPersonalTradeInValue: boolean;
  paymentFactors: PaymentFactors;
  unmatchedFilters?: SearchFilters;
}

function convertToQueryParams({
  pageSize,
  vehicleFilters,
  locale,
  favouriteVehicles,
}: VehicleListQuery) {
  const params = new URLSearchParams();
  params.append("pageSize", pageSize.toString());

  const filters =
    (vehicleFilters && vehicleFilters.filters) ?? (vehicleFilters || []);

  const fuelEfficiencyFilter = filters.find(
    (vf): vf is FuelEfficiencyFilter => vf.key === FilterKeys.FuelEfficiency
  );
  if (fuelEfficiencyFilter) {
    params.append(
      "efficiency.type",
      fuelEfficiencyFilter.state.consumptionType.id
    );
    params.append(
      "efficiency.min",
      fuelEfficiencyFilter.state.minLP100K
        ? fuelEfficiencyFilter.state.minLP100K.toString()
        : ""
    );
    params.append(
      "efficiency.max",
      fuelEfficiencyFilter.state.maxLP100K
        ? fuelEfficiencyFilter.state.maxLP100K.toString()
        : ""
    );
  }

  const yearFilter = filters.find(
    (vf): vf is YearFilter => vf.key === FilterKeys.Year
  );
  if (yearFilter) {
    params.append("year.from", yearFilter.state.minYear.value.toString());
    params.append("year.to", yearFilter.state.maxYear.value.toString());
  }

  const mileageFilter = filters.find(
    (vf): vf is MileageFilter => vf.key === FilterKeys.Mileage
  );
  if (mileageFilter) {
    params.append("mileage.min", mileageFilter.state.minMileage.toString());
    params.append("mileage.max", mileageFilter.state.maxMileage.toString());
  }

  const makeFilters = filters.filter(
    (vf): vf is MakeFilter => vf.key === FilterKeys.Make
  );
  if (makeFilters.length > 0) {
    makeFilters.forEach((mf, i) => {
      params.append(`makes[${i}]`, mf.state.make);
    });
  }

  const modelFilters = filters.filter(
    (vf): vf is ModelFilter => vf.key === FilterKeys.Model
  );
  if (modelFilters.length > 0) {
    modelFilters.forEach((mf, i) => {
      params.append(`models[${i}]`, mf.state.id);
    });
  }

  const featureFilters = filters.filter(
    (vf): vf is FeatureFilter => vf.key === FilterKeys.KeyFeatures
  );
  if (featureFilters.length > 0) {
    featureFilters.forEach((ff, i) => {
      params.append(`features[${i}]`, translate(ff.state.name, locale));
    });
  }

  const transmissionFilters = filters.filter(
    (vf): vf is TransmissionFilter => vf.key === FilterKeys.Transmission
  );
  if (transmissionFilters.length > 0) {
    transmissionFilters.forEach((tf, i) => {
      params.append(`transmissions[${i}]`, translate(tf.state.name, locale));
    });
  }

  const bodyStyleFilters = filters.filter(
    (vf): vf is BodyStyleFilter => vf.key === FilterKeys.BodyStyle
  );
  if (bodyStyleFilters.length > 0) {
    bodyStyleFilters.forEach((bf, i) => {
      params.append(`body[${i}]`, translate(bf.state.name, locale));
    });
  }

  const exteriorColourFilters = filters.filter(
    (vf): vf is ExteriorColourFilter => vf.key === FilterKeys.ExteriorColour
  );
  if (exteriorColourFilters.length > 0) {
    exteriorColourFilters.forEach((ecf, i) => {
      params.append(`colour[${i}]`, translate(ecf.state.name, locale));
    });
  }

  const fuelTypeFilters = filters.filter(
    (vf): vf is FuelTypeFilter => vf.key === FilterKeys.FuelType
  );
  if (fuelTypeFilters.length > 0) {
    fuelTypeFilters.forEach((ft, i) => {
      params.append(`fuelType[${i}]`, translate(ft.state.name, locale));
    });
  }

  const savedVehiclesFilter = filters.find(
    (vf) => vf.key === FilterKeys.SavedVehicles
  );
  if (savedVehiclesFilter) {
    favouriteVehicles.forEach((fv, i) => {
      params.append(`favourites[${i}]`, fv.vehicleId);
    });
  }

  const vehicleWarrantyFilter = filters.find(
    (vf) => vf.key === FilterKeys.Warranty
  );
  if (vehicleWarrantyFilter) {
    params.append("warranty", vehicleWarrantyFilter.state as string);
  }

  const textSearchFilter = filters.find((vf) => vf.key === FilterKeys.Search);
  if (textSearchFilter) {
    params.append("search", textSearchFilter.state as string);
  }

  const sortByFilters = filters.find(
    (vf): vf is SortByFilter => vf.key === FilterKeys.SortBy
  );
  if (sortByFilters) {
    params.append("sortBy", sortByFilters.state.id as string);
  }

  const paymentFilter = filters.find(
    (vf): vf is PaymentFilter => vf.key === FilterKeys.Payment
  );
  if (paymentFilter) {
    if (
      paymentFilter.state.minPayment != null &&
      paymentFilter.state.maxPayment != null
    ) {
      params.append("payment.min", paymentFilter.state.minPayment.toString());
      params.append("payment.max", paymentFilter.state.maxPayment.toString());
    }

    params.append(
      "payment.paymentFrequencyId",
      paymentFilter.state.paymentFrequency.id.toString()
    );
    params.append("payment.cashDown", paymentFilter.state.cashDown.toString());
    params.append(
      "payment.tradeInValue",
      paymentFilter.state.tradeInValue.toString()
    );
  }

  const priceFilter = filters.find(
    (vf): vf is PriceFilter => vf.key === FilterKeys.Price
  );
  if (priceFilter) {
    params.append("price.min", priceFilter.state.minPrice.toString());
    params.append("price.max", priceFilter.state.maxPrice.toString());
  }

  const locationFilters = filters.filter(
    (lf): lf is LocationFilter => lf.key === FilterKeys.Location
  );
  if (locationFilters.length > 0) {
    locationFilters.forEach((location, i) => {
      params.append(`locations[${i}]`, `${location.state.name}`);
    });
  }

  const provinceFilters = filters.filter(
    (pf): pf is ProvinceFilter => pf.key === FilterKeys.Province
  );
  if (provinceFilters.length > 0) {
    provinceFilters.forEach((prov, i) => {
      params.append(`provinces[${i}]`, `${prov.state.name}`);
    });
  }

  const passengerCapacityFilter = filters.filter(
    (pc): pc is PassengerCapacityFilter =>
      pc.key === FilterKeys.PassengerCapacity
  );
  if (passengerCapacityFilter) {
    passengerCapacityFilter.forEach((pc) => {
      params.append("passengerCapacity", pc.state.name);
    });
  }

  return params;
}

const getVehicles = async (
  filtersParams: string,
  pageIndex: number,
  locale: string
): Promise<VehicleListQueryResult> => {
  const requestUrl = `api/vehicles?pageIndex=${pageIndex}&${filtersParams}`;

  return api.get<VehicleListQueryResult>(requestUrl, {
    parseJson: (text: string) => JSON.parse(text, vehicleListReviver),
    headers: { "Accept-Language": locale },
  });
};

const getRecommendedVehicles = async (
  filtersParams: string,
  pageIndex: number,
  locale: string
): Promise<VehicleListQueryResult> => {
  const requestUrl = `api/vehicles/recommended?pageIndex=${pageIndex}&${filtersParams}`;

  return api.get<VehicleListQueryResult>(requestUrl, {
    parseJson: (text: string) => JSON.parse(text, vehicleListReviver),
    headers: { "Accept-Language": locale },
  });
};

function convertFiltersToSearchParams({
  pageSize,
  vehicleFilters,
  locale,
}: VehicleListQuery) {
  const searchStringParts: string[] = [];

  const params = new URLSearchParams();
  params.append("pageSize", pageSize.toString());

  const { filters } = vehicleFilters;

  const yearFilter = filters.find(
    (vf): vf is YearFilter => vf.key === FilterKeys.Year
  );
  if (yearFilter) {
    searchStringParts.push(
      `${yearFilter.state.minYear.value}-${yearFilter.state.maxYear.value}`
    );
  }

  const makeFilters = filters.filter(
    (vf): vf is MakeFilter => vf.key === FilterKeys.Make
  );
  if (makeFilters.length > 0) {
    makeFilters.forEach((mf) => {
      searchStringParts.push(`${mf.state.make}`);
    });
  }

  const modelFilters = filters.filter(
    (vf): vf is ModelFilter => vf.key === FilterKeys.Model
  );
  if (modelFilters.length > 0) {
    modelFilters.forEach((mf) => {
      searchStringParts.push(`${mf.state.make} ${mf.state.model}`);
    });
  }

  const featureFilters = filters.filter(
    (vf): vf is FeatureFilter => vf.key === FilterKeys.KeyFeatures
  );
  if (featureFilters.length > 0) {
    featureFilters.forEach((ff) => {
      searchStringParts.push(`${translate(ff.state.name, locale)}`);
    });
  }

  const transmissionFilters = filters.filter(
    (vf): vf is TransmissionFilter => vf.key === FilterKeys.Transmission
  );
  if (transmissionFilters.length > 0) {
    transmissionFilters.forEach((tf) => {
      searchStringParts.push(`${translate(tf.state.name, locale)}`);
    });
  }

  const warrantyFilter = filters.find((vf) => vf.key === FilterKeys.Warranty);
  if (warrantyFilter) {
    searchStringParts.push(`${warrantyFilter.state as string}`);
  }

  const bodyStyleFilters = filters.filter(
    (vf): vf is BodyStyleFilter => vf.key === FilterKeys.BodyStyle
  );
  if (bodyStyleFilters.length > 0) {
    bodyStyleFilters.forEach((bf) => {
      searchStringParts.push(`${translate(bf.state.name, locale)}`);
    });
  }

  const exteriorColourFilters = filters.filter(
    (vf): vf is ExteriorColourFilter => vf.key === FilterKeys.ExteriorColour
  );
  if (exteriorColourFilters.length > 0) {
    exteriorColourFilters.forEach((ecf) => {
      searchStringParts.push(`${translate(ecf.state.name, locale)}`);
    });
  }

  const fuelTypeFilters = filters.filter(
    (vf): vf is FuelTypeFilter => vf.key === FilterKeys.FuelType
  );
  if (fuelTypeFilters.length > 0) {
    fuelTypeFilters.forEach((ft) => {
      searchStringParts.push(`${translate(ft.state.name, locale)}`);
    });
  }

  const locationFilters = filters.filter(
    (vf): vf is LocationFilter => vf.key === FilterKeys.Location
  );
  if (locationFilters.length > 0) {
    locationFilters.forEach((lf) => {
      searchStringParts.push(lf.state.name);
    });
  }

  const provinceFilters = filters.filter(
    (pf): pf is ProvinceFilter => pf.key === FilterKeys.Province
  );
  if (provinceFilters.length > 0) {
    provinceFilters.forEach((pf) => {
      searchStringParts.push(pf.state.name);
    });
  }

  const paymentFilter = filters.find(
    (vf): vf is PaymentFilter => vf.key === FilterKeys.Payment
  );
  if (paymentFilter) {
    params.append(
      "payment.paymentFrequencyId",
      paymentFilter.state.paymentFrequency.id.toString()
    );
    params.append("payment.cashDown", paymentFilter.state.cashDown.toString());
    params.append(
      "payment.tradeInValue",
      paymentFilter.state.tradeInValue.toString()
    );
  }

  const passengerCapacityFilter = filters.find(
    (pc): pc is PassengerCapacityFilter =>
      pc.key === FilterKeys.PassengerCapacity
  );
  if (passengerCapacityFilter) {
    searchStringParts.forEach((pc) => {
      searchStringParts.push(pc);
    });
  }

  const textSearchFilter = filters.find((vf) => vf.key === FilterKeys.Search);
  if (textSearchFilter) {
    searchStringParts.push(`${textSearchFilter.state as string}`);
  }

  params.append(
    "search",
    truncateFullWords(searchStringParts.join(" "), maxSearchLength)
  );
  return params;
}

export default function useVehicleList(
  query: VehicleListQuery,
  vehicleFilterData?: VehicleFiltersData | null,
  withRecommended?: boolean
) {
  const filtersParams = convertToQueryParams(query).toString();

  const rslt = useInfiniteQuery({
    queryKey: vehicleQueryKeys.list(filtersParams, query.locale),
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      let vRslt = await getVehicles(filtersParams, pageParam, query.locale);
      const { matchedFilters } = vRslt;

      gtmPushData({ recentSearchQuery: filtersParams });

      if (withRecommended && vRslt.vehicles.length === 0) {
        vRslt = await getRecommendedVehicles(
          convertFiltersToSearchParams(query).toString(),
          pageParam,
          query.locale
        );
        vRslt.isRecommended = true;
        // store the matched filter for original search query because we use it to update the filter state
        vRslt.unmatchedFilters = matchedFilters;
      }

      return vRslt;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageIndex ?? undefined;
    },
    enabled: query.enabled && vehicleFilterData !== null,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const filters = query.vehicleFilters.filters ?? [];

  const sort = filters.find(
    (vf): vf is SortByFilter => vf.key === FilterKeys.SortBy
  );

  // manipulate the sort by lowest payment in the useVehicleList hook instead of within the component scope to prevent page re-render
  if (rslt.data && sort && sort.state.id === LowestPaymentSortBy.id) {
    const sortedVehicles = rslt.data.pages
      .flatMap((p) => p.vehicles)
      .sort((a, b) => {
        // Check if both vehicles have prices
        const aHasPrice = a.pricing.listingPrice > 0;
        const bHasPrice = b.pricing.listingPrice > 0;

        // If both have prices, sort by the payment amount
        if (aHasPrice && bHasPrice) {
          return a.pricing.paymentAmount - b.pricing.paymentAmount;
        }
        // If only 'a' has a price, it should come before 'b'
        if (aHasPrice) {
          return -1;
        }
        // If only 'b' has a price, it should come before 'a'
        if (bHasPrice) {
          return 1;
        }
        // If neither have a price, they are equal
        return 0;
      });

    let index = 0;
    rslt.data.pages = rslt.data.pages.map((page) => {
      const updatedPage = {
        ...page,
        vehicles: sortedVehicles.slice(index, index + page.vehicles.length),
      };
      index += page.vehicles.length;
      return updatedPage;
    });
  }

  return rslt;
}
