import api from "@modules/api/api";
import { getGuestUser, User } from "@modules/user/types/user";
import userQueryKeys from "@modules/user/userQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Notification from "@common/types/Notification";
import { UserSavedSearches } from "../types/UserSavedSearches";
import {
  BodyStyleFilter,
  ExteriorColourFilter,
  FeatureFilter,
  FilterKeys,
  FuelEfficiencyFilter,
  FuelTypeFilter,
  MakeFilter,
  MileageFilter,
  ModelFilter,
  PaymentFilter,
  PriceFilter,
  TransmissionFilter,
  VehicleFilter,
  VehicleSearchFilter,
  YearFilter,
  LocationFilter,
  ProvinceFilter,
  WarrantyFilter,
  PassengerCapacityFilter,
} from "../types/vehicleFilter";

type SavedFilters = {
  year: { from: number; to: number } | null;
  mileage: { min: number; max: number } | null;
  makes: string[];
  models: string[];
  features: string[];
  transmissions: string[];
  body: string[];
  colour: string[];
  search: string | null;
  fuelTypes: string[];
  efficiency: { type: string; min: number; max: number } | null;
  payment: {
    min: number;
    max: number;
    paymentFrequencyId: number;
    cashDown: number;
    tradeInValue: number;
  } | null;
  price: { min: number; max: number } | null;
  locations: string[];
  provinces: string[];
  warranty: boolean;
  passengerCapacity: string[];
};

export function convertSavedFilters(filters: VehicleFilter[]): SavedFilters {
  const savedFilters: SavedFilters = {
    year: null,
    mileage: null,
    makes: [],
    models: [],
    features: [],
    transmissions: [],
    body: [],
    colour: [],
    search: null,
    fuelTypes: [],
    efficiency: null,
    payment: null,
    price: null,
    locations: [],
    provinces: [],
    warranty: false,
    passengerCapacity: [],
  };

  const yearFilter = filters.find(
    (vf): vf is YearFilter => vf.key === FilterKeys.Year
  );
  if (yearFilter) {
    savedFilters.year = {
      from: yearFilter.state.minYear.value,
      to: yearFilter.state.maxYear.value,
    };
  }

  const mileageFilter = filters.find(
    (vf): vf is MileageFilter => vf.key === FilterKeys.Mileage
  );
  if (mileageFilter) {
    savedFilters.mileage = {
      min: mileageFilter.state.minMileage,
      max: mileageFilter.state.maxMileage,
    };
  }

  const makeFilters = filters.filter(
    (vf): vf is MakeFilter => vf.key === FilterKeys.Make
  );
  if (makeFilters.length > 0) {
    makeFilters.forEach((mf) => {
      savedFilters.makes.push(mf.state.make);
    });
  }

  const modelFilters = filters.filter(
    (vf): vf is ModelFilter => vf.key === FilterKeys.Model
  );
  if (modelFilters.length > 0) {
    modelFilters.forEach((mf) => {
      savedFilters.models.push(mf.state.id);
    });
  }

  const featureFilters = filters.filter(
    (vf): vf is FeatureFilter => vf.key === FilterKeys.KeyFeatures
  );
  if (featureFilters.length > 0) {
    featureFilters.forEach((ff) => {
      savedFilters.features.push(ff.state.name.english);
    });
  }

  const transmissionFilters = filters.filter(
    (vf): vf is TransmissionFilter => vf.key === FilterKeys.Transmission
  );
  if (transmissionFilters.length > 0) {
    transmissionFilters.forEach((tf) => {
      savedFilters.transmissions.push(tf.state.name.english);
    });
  }

  const bodyStyleFilters = filters.filter(
    (vf): vf is BodyStyleFilter => vf.key === FilterKeys.BodyStyle
  );
  if (bodyStyleFilters.length > 0) {
    bodyStyleFilters.forEach((bf) => {
      savedFilters.body.push(bf.state.name.english);
    });
  }

  const exteriorColourFilters = filters.filter(
    (vf): vf is ExteriorColourFilter => vf.key === FilterKeys.ExteriorColour
  );
  if (exteriorColourFilters.length > 0) {
    exteriorColourFilters.forEach((ecf) => {
      savedFilters.colour.push(ecf.state.name.english);
    });
  }

  const textSearchFilter = filters.find(
    (vf): vf is VehicleSearchFilter => vf.key === FilterKeys.Search
  );
  if (textSearchFilter) {
    savedFilters.search = textSearchFilter.state;
  }

  const warrantyFilter = filters.find(
    (vf): vf is WarrantyFilter => vf.key === FilterKeys.Warranty
  );
  if (warrantyFilter) {
    savedFilters.warranty = warrantyFilter.state;
  }

  const fuelTypeFilters = filters.filter(
    (vf): vf is FuelTypeFilter => vf.key === FilterKeys.FuelType
  );
  if (fuelTypeFilters.length > 0) {
    fuelTypeFilters.forEach((ft) => {
      savedFilters.fuelTypes.push(ft.state.name.english);
    });
  }

  const fuelEfficiencyFilter = filters.find(
    (vf): vf is FuelEfficiencyFilter => vf.key === FilterKeys.FuelEfficiency
  );
  if (fuelEfficiencyFilter) {
    savedFilters.efficiency = {
      type: fuelEfficiencyFilter.state.consumptionType.id,
      min: fuelEfficiencyFilter.state.minLP100K,
      max: fuelEfficiencyFilter.state.maxLP100K,
    };
  }

  const paymentFilter = filters.find(
    (vf): vf is PaymentFilter => vf.key === FilterKeys.Payment
  );
  if (
    paymentFilter &&
    paymentFilter.state.minPayment != null &&
    paymentFilter.state.maxPayment != null
  ) {
    savedFilters.payment = {
      min: paymentFilter.state.minPayment,
      max: paymentFilter.state.maxPayment,
      paymentFrequencyId: paymentFilter.state.paymentFrequency.id,
      cashDown: paymentFilter.state.cashDown,
      tradeInValue: paymentFilter.state.tradeInValue,
    };
  }

  const priceFilter = filters.find(
    (vf): vf is PriceFilter => vf.key === FilterKeys.Price
  );
  if (priceFilter) {
    savedFilters.price = {
      min: priceFilter.state.minPrice,
      max: priceFilter.state.maxPrice,
    };
  }

  const locationFilters = filters.filter(
    (vf): vf is LocationFilter => vf.key === FilterKeys.Location
  );
  if (locationFilters.length > 0) {
    locationFilters.forEach((lf) => {
      savedFilters.locations.push(lf.state.name);
    });
  }

  const provinceFilters = filters.filter(
    (vf): vf is ProvinceFilter => vf.key === FilterKeys.Province
  );
  if (provinceFilters.length > 0) {
    provinceFilters.forEach((pf) => {
      savedFilters.provinces.push(pf.state.name);
    });
  }

  const passengerCapacityFilters = filters.filter(
    (vf): vf is PassengerCapacityFilter =>
      vf.key === FilterKeys.PassengerCapacity
  );
  if (passengerCapacityFilters.length > 0) {
    passengerCapacityFilters.forEach((pcf) => {
      savedFilters.passengerCapacity.push(pcf.state.name);
    });
  }

  return savedFilters;
}

export type SaveSearchRequest = {
  searchName: string;
  queryParams: string;
  vehicleFilters: VehicleFilter[];
  defaultSearchName: string;
  notificationId?: Notification | null;
};

const saveSearch = async (
  saveSearchRequest: SaveSearchRequest
): Promise<UserSavedSearches> => {
  return api.post<UserSavedSearches>(
    "api/customers/me/vehicle-searches/saved",
    {
      json: {
        name: saveSearchRequest.searchName,
        queryParameters: saveSearchRequest.queryParams,
        vehicleFilters: convertSavedFilters(saveSearchRequest.vehicleFilters),
        defaultName: saveSearchRequest.defaultSearchName,
        notificationId: saveSearchRequest.notificationId,
      },
    }
  );
};

export default function useSaveSearchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveSearch,
    onSuccess: (newSavedSearch) => {
      queryClient.setQueryData(
        userQueryKeys.user(),
        (data: User | undefined) => {
          const user = data || getGuestUser();
          const { savedSearches } = user;

          return {
            ...user,
            savedSearches: [...(savedSearches || []), newSavedSearch],
          };
        }
      );
    },
  });
}
