import classNames from "classnames";
import { ComponentPropsWithoutRef } from "react";

export type Props = ComponentPropsWithoutRef<"button">;

const CircularButton = ({ children, className, ...props }: Props) => {
  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center border rounded-full",
        className
      )}
      aria-label="Add this vehicle to saved vehicles"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </button>
  );
};

export default CircularButton;
