import getDistanceEvaluation, {
  convertDealerLocationToMapLocation,
  isLocationWithinDefaultMaxRange,
  findClosestLocation,
} from "@common/helpers/distanceMatrixHelper";
import Location from "@modules/locations/types/Location";
import { User } from "@modules/user/types/user";

export default async function determineLeadDealer(
  locations: Location[],
  user: User,
  vehicleLocation: Location,
  fallbackLocation: Location
): Promise<Location> {
  let result;

  const vehicleMapLocation =
    convertDealerLocationToMapLocation(vehicleLocation);

  // if we have the user's location...
  if (user?.longitude && user.latitude && vehicleLocation) {
    const distancesFromVehicle = getDistanceEvaluation(
      user.latitude,
      user.longitude,
      [vehicleMapLocation]
    );

    if (distancesFromVehicle.length > 0) {
      const distanceFromVehicleDealer = distancesFromVehicle[0];

      // if the user is within the max range of the vehicle's dealer
      if (isLocationWithinDefaultMaxRange(distanceFromVehicleDealer)) {
        result = vehicleLocation;
      } else {
        const mapLocation = findClosestLocation(
          user.latitude ?? 0,
          user.longitude ?? 0,
          locations.map((l) => convertDealerLocationToMapLocation(l))
        );

        if (mapLocation) {
          const foundLocation = locations.find((x) => x.id === mapLocation.id);

          if (foundLocation) {
            result = foundLocation;
          }
        }
      }
    }
  }

  if (!result) {
    // user did not share their location, send to either the vehicle's dealer if it's close to their selected
    // location, or to their selected location otherwise

    // selected location should not be undefined here
    const distances = getDistanceEvaluation(
      fallbackLocation?.address.latitude ?? 0,
      fallbackLocation?.address.longitude ?? 0,
      [vehicleMapLocation]
    );

    if (distances.length > 0) {
      const distance = distances[0];
      if (isLocationWithinDefaultMaxRange(distance)) {
        result = vehicleLocation;
      }
    }
  }

  if (!result) {
    result = fallbackLocation;
  }

  return result;
}
