import { ArrowRightIcon, SearchIcon } from "@common/components/icons";
import { Popover } from "@headlessui/react";
import useGetVehicleFilterData from "@modules/vehicle/hooks/useVehicleFilterData";
import useSaveRecentSearchMutation from "@modules/vehicle/hooks/useSaveRecentSearchMutation";
import {
  FilterActions,
  FilterKeys,
} from "@modules/vehicle/types/vehicleFilter";
import { VehicleSearchRecord } from "@modules/vehicle/types/VehicleSearchRecord";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
  useState,
  useContext,
  KeyboardEvent,
  FormEvent,
  useRef,
  ChangeEvent,
} from "react";
import VehicleFilterContext from "@modules/vehicle/VehicleFilterContext";
import Button from "@common/components/Button";
import convertToQueryParams from "@modules/vehicle/helpers/convertToQueryParams";
import useOnClickOutside from "@common/hooks/useOnClickOutside";
import { UserSavedSearches } from "@modules/vehicle/types/UserSavedSearches";
import { maxSearchLength } from "@modules/vehicle/hooks/useVehicleList";
import isVehicleListingPage, {
  VLP_PATH,
} from "@common/helpers/isVehicleListingPage";
import { CmsButtonType } from "@modules/cms/types/CmsButton";
import GetButtonStyle from "@modules/cms/helpers/getButtonStyle";
import SuggestedSearchBox from "./SuggestedSearchBox";

type Props = {
  value?: string;
  recentSearches?: UserSavedSearches[];
  savedSearches?: UserSavedSearches[];
  search?: {
    isActive: boolean;
    searchBarLabel: string;
    button: CmsButtonType;
  };
};

const VehicleSearch = ({
  value,
  recentSearches,
  savedSearches,
  search,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const { push, pathname, locale } = useRouter();
  const [searchValue, setSearchValue] = useState(value || "");
  const { vehicleFilters: vehicleFiltersData } = useGetVehicleFilterData();
  const { dispatch } = useContext(VehicleFilterContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isGoButtonShown, setIsGoButtonShown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const popOverRef = useRef(null);
  const saveRecentSearchMutation = useSaveRecentSearchMutation();

  useOnClickOutside([popOverRef], () => {
    setIsPopoverOpen(false);
    setIsGoButtonShown(false);
  });

  const updateSearch = (trimmedInput: string) => {
    if (!vehicleFiltersData) return;

    // Save recent search if Search filter is present
    if (trimmedInput && trimmedInput !== "") {
      saveRecentSearchMutation.mutate({
        name: trimmedInput,
        vehicleFilters: [
          {
            key: FilterKeys.Search,
            state: trimmedInput,
          },
        ],
      });
    }

    if (!isVehicleListingPage(pathname)) {
      push(
        { pathname: `/${VLP_PATH}`, query: { search: trimmedInput } },
        undefined,
        {
          shallow: true,
        }
      );
    } else {
      dispatch({
        type: FilterActions.SetSearchFilters,
        payload: {
          key: FilterKeys.Search,
          state: trimmedInput,
          originalString: trimmedInput,
        },
      });
    }

    setSearchValue("");
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const trimmedInput = searchValue.trim();

    updateSearch(trimmedInput);

    inputRef.current?.blur();
    setIsPopoverOpen(false);
    setIsGoButtonShown(false);
  };

  const selectSearchSuggestion = (
    selectedSearch: VehicleSearchRecord,
    saveSearch = true
  ) => {
    if (selectedSearch.searchText) {
      updateSearch(selectedSearch.searchText);
    } else if (selectedSearch.filters) {
      if (saveSearch) {
        saveRecentSearchMutation.mutate({
          name: selectedSearch.name,
          vehicleFilters: selectedSearch.filters,
        });
      }

      if (!isVehicleListingPage(pathname)) {
        const queryParams = convertToQueryParams(
          selectedSearch.filters,
          locale
        );

        push({
          pathname: `/${VLP_PATH}`,
          query: queryParams,
        });
      } else {
        dispatch({
          type: FilterActions.SetInitialFilters,
          payload: selectedSearch.filters,
        });
      }
    }

    setIsPopoverOpen(false);
    setIsGoButtonShown(false);
  };

  const handleInputFocus = () => {
    setIsPopoverOpen(true);
    setIsGoButtonShown(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    setIsPopoverOpen(true);
  };

  return (
    <>
      {!search?.isActive && (
        <Popover className="relative" ref={popOverRef}>
          <form method="GET" onSubmit={handleSubmit} className="relative">
            <input
              type="text"
              placeholder={t("common:main_search_place_holder")}
              aria-label={t("common:main_search_place_holder")}
              className={classNames(
                "relative text-sm w-full peer leading-6 bg-white text-left overflow-hidden rounded-lg border border-other-light-100 placeholder-gray-300 pl-[31px] focus:outline-none focus:ring-2 focus:ring-primary-bright focus:border-transparent lg:body-3 lg:leading-6",
                {
                  "ring-2 ring-primary-bright border-transparent rounded-b-none":
                    isPopoverOpen,
                }
              )}
              onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Escape") {
                  inputRef.current?.blur();
                  setIsPopoverOpen(false);
                }
              }}
              onFocus={handleInputFocus}
              value={searchValue}
              onChange={handleInputChange}
              ref={inputRef}
              maxLength={maxSearchLength}
              data-hj-allow
            />
            <span
              className={classNames(
                "absolute inset-y-2 left-0 top-1 flex items-center pl-2 h-8 w-8 text-text-light-200 pointer-events-none",
                { "top-[4px]": isPopoverOpen }
              )}
            >
              <SearchIcon className="text-primary-bold w-6 h-6" />
            </span>
            {isGoButtonShown && (
              <Button
                fill="outline"
                size="small"
                spacing="hug"
                isSubmit
                className="absolute top-1 right-1 font-semibold hidden lg:inline-flex focus:outline-none focus-visible:shadow-elevation-03"
              >
                {t("common:go")}
              </Button>
            )}
          </form>

          {isPopoverOpen && (
            <Popover.Panel
              static
              className="absolute z-10 -mt-[4px] max-h-64 w-full overflow-auto rounded-b-lg bg-white py-1 text-sm shadow-lg border-t-0 vehicle-search-popover focus:outline-none lg:text-base"
              onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Escape") {
                  inputRef.current?.blur();
                  setIsPopoverOpen(false);
                }
              }}
            >
              <div className="mx-2 border-t-2 border-other-light-200" />
              <div className="px-6">
                {vehicleFiltersData && (
                  <SuggestedSearchBox
                    searchText={searchValue}
                    onSearchSelect={selectSearchSuggestion}
                    filterData={vehicleFiltersData}
                    savedSearchesData={savedSearches}
                    recentSearchesData={recentSearches}
                  />
                )}
              </div>
            </Popover.Panel>
          )}
        </Popover>
      )}
      {search?.isActive && (
        <Popover className="relative mt-7" ref={popOverRef}>
          <form method="GET" onSubmit={handleSubmit} className="relative flex">
            <div className="relative w-full">
              <input
                type="text"
                placeholder={search.searchBarLabel}
                aria-label={t("common:main_search_place_holder")}
                className={classNames(
                  "relative text-sm w-full peer leading-6 bg-white text-left overflow-hidden rounded-lg border border-other-light-100 placeholder-gray-300 pl-[31px] focus:outline-none focus:ring-2 focus:ring-primary-bright focus:border-transparent lg:body-3 lg:leading-6",
                  {
                    "ring-2 ring-primary-bright border-transparent rounded-b-none":
                      isPopoverOpen,
                  }
                )}
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === "Escape") {
                    inputRef.current?.blur();
                    setIsPopoverOpen(false);
                  }
                }}
                onFocus={handleInputFocus}
                value={searchValue}
                onChange={handleInputChange}
                ref={inputRef}
                maxLength={maxSearchLength}
                data-hj-allow
              />
              <span
                className={classNames(
                  "absolute inset-y-2 left-0 top-1 flex items-center pl-2 h-8 w-8 text-text-light-200 pointer-events-none",
                  { "top-[4px]": isPopoverOpen }
                )}
              >
                <SearchIcon className="text-primary-bold w-6 h-6" />
              </span>
              {isPopoverOpen && (
                <Popover.Panel
                  static
                  className="absolute z-10 -mt-[4px] max-h-64 w-full overflow-auto rounded-b-lg bg-white py-1 text-sm shadow-lg border-t-0 vehicle-search-popover focus:outline-none lg:text-base"
                  onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Escape") {
                      inputRef.current?.blur();
                      setIsPopoverOpen(false);
                    }
                  }}
                >
                  <div className="mx-2 border-t-2 border-other-light-200" />
                  <div className="px-6">
                    {vehicleFiltersData && (
                      <SuggestedSearchBox
                        searchText={searchValue}
                        onSearchSelect={selectSearchSuggestion}
                        filterData={vehicleFiltersData}
                        savedSearchesData={savedSearches}
                        recentSearchesData={recentSearches}
                      />
                    )}
                  </div>
                </Popover.Panel>
              )}
            </div>
            <Button
              fill={search.button.buttonType || "filled"}
              buttonStyle={GetButtonStyle(search.button) || "primary"}
              rightIcon={<ArrowRightIcon />}
              isSubmit
              size="large"
              spacing="tight-hug"
              className="ml-3 px-2 h-fit"
            >
              {search.button.actionButtonTitle}
            </Button>
          </form>
        </Popover>
      )}
    </>
  );
};

export default VehicleSearch;
