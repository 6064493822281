import ky from "ky-universal";
import { Input, Options } from "ky/distribution/types/options";
import base from "./base";

const kyInstance = ky.create({
  retry: 0,
  timeout: 30000,
});

const getUrlObject = (urlPath: string): Input => {
  const url: URL = new URL(`${process.env.NEXT_PUBLIC_HOST}/${urlPath}`);
  return url;
};

const nextApi = {
  get: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.get(
      kyInstance,
      getUrlObject(url as string),
      options
    );
    return res as Promise<T>;
  },
  post: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.post(
      kyInstance,
      getUrlObject(url as string),
      options
    );
    return res as Promise<T>;
  },
  put: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.put(
      kyInstance,
      getUrlObject(url as string),
      options
    );
    return res as Promise<T>;
  },
  delete: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.delete(
      kyInstance,
      getUrlObject(url as string),
      options
    );
    return res as Promise<T>;
  },
};

export default nextApi;
