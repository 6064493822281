import { VehicleDetail } from "@modules/vehicle/types/VehicleDetail";
import VehicleList from "@modules/vehicle/types/vehicleList";

declare global {
  interface Window {
    dataLayer: object[];
  }
}

type GtmData = object;

const persistentKeys = [
  "user_id",
  "vehicleYear",
  "vehicleMake",
  "vehicleModel",
  "vehicleStockNumber",
  "vehicleBodyType",
  "vehicleExteriorColor",
  "vehicleTransmission",
  "vehicleFuelType",
  "vehicleDrivetrain",
  "vehiclePrice",
  "vehicleHasSpinUrl",
  "items",
  "content_type",
  "content_ids",
  "currency",
  "state_of_vehicle",
  "country",
  "kijijiAppId",
  "kijijiSessionId",
  "kijijiParamIds",
];
let dataLayerKeys: string[] = [];

const gtmAvailable = (): boolean => typeof window !== "undefined";

export const gtmPushData = (items: GtmData) => {
  if (!gtmAvailable()) {
    return;
  }

  window.dataLayer = window.dataLayer ?? [];

  // Default behavior is to merge arrays
  // If any item is an array type, clear the array and overwrite
  Object.entries(items).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      window.dataLayer.push({ [key]: value, _clear: true });
    }
  });

  // Reset previous dataLayer items by setting to undefined
  const resetItems = Object.fromEntries(
    dataLayerKeys.map((key) => [key, undefined])
  );

  // Push reset items and new items
  window.dataLayer.push({ ...resetItems, ...items });

  // Save non-persistent item keys for reset next time
  dataLayerKeys = Object.keys(items).filter(
    (key) => !persistentKeys.includes(key)
  );
};

export const formatVehicleString = (
  vehicle: VehicleDetail | VehicleList | null | undefined
): string => {
  return vehicle
    ? `${vehicle.year}-${vehicle.make}-${vehicle.model}-${vehicle.stockNumber}`
    : "";
};
