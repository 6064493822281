import { ButtonStyle } from "@common/components/Button";
import { CmsButtonType } from "../types/CmsButton";

const GetButtonStyle = (button: CmsButtonType): ButtonStyle | undefined => {
  let buttonStyle: ButtonStyle;
  switch (button.buttonType) {
    case "filled":
      buttonStyle = button.buttonStyleFilled;
      break;
    case "outline":
      buttonStyle = button.buttonStyleOutline;
      break;
    case "link":
      buttonStyle = button.buttonStyleLink;
      break;
    default:
      return undefined;
  }
  return buttonStyle;
};

export default GetButtonStyle;
